<template>
    <div class="notice-container">
        <div class="notice-banner">
        </div>
        <div class="notice-content">
            <div class="notice-text" v-html="obj.content" v-if="obj.content"></div>
            <div v-else class="notice-nodata">暂无公告</div>
            <div class="notice-file" v-if="fileList && fileList.length>0">
                <h3>附件</h3>
                <ul>
                    <li v-for="(item,index) in fileList" :key="index">
                        <svg-icon :iconClass="getType(item.name)=='pdf'?'doc-icon':'zip-icon'"></svg-icon>
                        <span class="name">{{item.name}}</span>
                        <a :href="item.url" target="_blank" class="ptx" v-if="getType(item.name)=='pdf'">预览</a>
                        <span class="ptx" v-else @click="handleDownload(item.url,item.name)">下载</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Notice',
    data(){
        return{
            obj:{
                content:'',
            },
            fileList:[]
        }
    },
    created(){
        this.getDetail()
    },
    methods: {
        getDetail(){
            this.$api.cms.getNotice({branchId:localStorage.getItem('branchId')}).then(res=>{
                if(res.code==0){
                    this.obj = res.data;
                    this.fileList = res.data.enclosureJson && JSON.parse(res.data.enclosureJson) || []
                }
            })
        },
        getType(fileName){
            return fileName.substring(fileName.lastIndexOf(".") + 1)
        },
        handleDownload(imgUrl,name) {
            fetch(imgUrl, {
                method: "get",
                headers: new Headers({
                Origin: location.origin
                }),
                mode: "cors",
            }).then((res) =>
                res.blob().then((blob) => {
                var reader = new FileReader();
                reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                reader.onload = function (e) {
                    var down = document.createElement("a"); // 转换完成，创建一个a标签用于下载
                    down.download = name;
                    down.href = String(e.target.result);
                    down.click();
                    down.remove();
                };
                })
            );
        }

    },
}
</script>
<style lang="stylus">
.notice-container{
    background:#fff;
    min-height:calc(100vh - 72px);
    box-sizing:border-box;
}
.notice-banner{
    width: 100%;
    height: 300px;
    background: url(https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/notice-banner.png) no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom:24px;
}
.notice-nodata{
    display:flex;
    justify-content:center;
    align-items:center;
    height:300px;
    text-align:center;
    font-size:32px;
}
.notice-content{
    width:1200px;
    margin:0 auto 40px;
    .notice-text{
        padding:24px 0;
        img{
            max-width:100% !important;
        }
    }
    .notice-file{
        border-top: 1px solid #edf1f8;
        padding:24px 0;
    }
    h3{
        font-size:18px;
        margin-bottom:12px;
        font-weight:normal;
        color:#333;
    }
    ul{
        padding-left:16px;
    }
    li{
        line-height:28px;
        display:flex;
        align-items:center;
        width:390px;
         &:hover{
            .name{
                color:#1972ff;
            }
        }
        .ptx{
            color:#1972ff;
            margin-left:10px;
            &:hover{
                cursor:pointer;
            }
        }
        .name{
                color: #999;
                width: 320px;
                margin-left:6px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                word-wrap: break-word;
        }
    }
}
</style>

